/* Hotwire's version of data-disable-with */
button                  .when-disabled { display: none; }
button[disabled]        .when-disabled { display: initial; }
button                  .when-enabled { display: initial; }
button[disabled]        .when-enabled { display: none; }

a.link-primary {
  @apply text-primary-600;

  &:hover,
  &:focus {
    @apply text-primary-500;

    transform: none;
  }
}

.nav-link {
  @apply flex gap-x-3 lg:gap-x-1.5 py-2 px-3 w-full lg:w-auto items-center text-sm text-start text-stone-800 rounded-lg hover:bg-stone-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-stone-100 dark:text-neutral-200 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700
}

.nav-link.active {
  @apply bg-stone-100 focus:bg-stone-200 dark:bg-neutral-700 dark:focus:bg-neutral-600
}

.form-group label {
  @apply block mb-2 text-sm font-medium text-stone-800 dark:text-white;
}

input.form-control {
  @apply py-2.5 px-3 block w-full border-stone-200 rounded-lg text-sm placeholder:text-stone-400 focus:border-primary-500 focus:ring-primary-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-transparent dark:border-neutral-700 dark:text-neutral-300 dark:placeholder:text-white/60 dark:focus:ring-neutral-600;
}

textarea.form-control {
  @apply py-2 px-3 block w-full border-gray-200 rounded-lg text-sm placeholder:text-gray-400 focus:border-primary-500 focus:ring-primary-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-transparent dark:border-neutral-700 dark:text-neutral-300 dark:placeholder:text-white/60 dark:focus:ring-neutral-600;
}

select.form-select,
select.select {
  @apply py-2.5 px-3 pe-9 block w-full border-stone-200 rounded-lg text-sm placeholder:text-stone-400 focus:border-primary-500 focus:ring-primary-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-transparent dark:border-neutral-700 dark:text-neutral-300 dark:placeholder:text-white/60 dark:focus:ring-neutral-600;
}

.btn.btn-primary {
  @apply py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-primary-600 text-white hover:bg-primary-700 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:ring-2 focus:ring-primary-500
}

.btn.btn-secondary {
  @apply py-2 px-3 inline-flex justify-center items-center text-start bg-white border border-gray-200 text-gray-800 text-sm font-medium rounded-lg shadow-sm align-middle hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700
}

.banner {
}

.banner-info {
}

.banner-warning {
  @apply bg-red-50 border border-red-200 text-sm text-red-800 rounded-lg p-4 dark:bg-red-800/10 dark:border-red-900 dark:text-red-500;
}

.icon-alert {
  @apply shrink-0 size-4 mt-0.5;
}

.icon-notice {
  @apply shrink-0 size-4 mt-0.5;
}

.icon-success {
  @apply shrink-0 size-4 mt-0.5;
}

.icon-default {
  @apply shrink-0 size-4 mt-0.5;
}

.form-hint {
  @apply text-sm mt-2;

  &.error {
    @apply text-red-600;
  }
}

nav.pagy.nav {
  @apply flex items-center gap-x-1;

  a:first-child {
    @apply min-h-[38px] min-w-[38px] py-2 px-2.5 inline-flex justify-center items-center gap-x-2 text-sm rounded-lg border border-transparent text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:border-transparent dark:text-white dark:hover:bg-white/10 dark:focus:bg-white/10;
  }

  a:last-child {
    @apply min-h-[38px] min-w-[38px] flex justify-center items-center border border-transparent text-gray-800 hover:bg-gray-100 py-2 px-3 text-sm rounded-lg focus:outline-none focus:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:border-transparent dark:text-white dark:hover:bg-white/10 dark:focus:bg-white/10;
  }

  a:not(.gap) {
    @apply min-h-[38px] min-w-[38px] flex justify-center items-center border border-transparent text-gray-800 hover:bg-gray-100 py-2 px-3 text-sm rounded-lg focus:outline-none focus:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:border-transparent dark:text-white dark:hover:bg-white/10 dark:focus:bg-white/10;

    &:hover {
    }

    &[aria-current="page"].current {
      @apply border-gray-200;
    }

    &:not([href]) { /* disabled links */
    }

    &.current {
      @apply border-gray-200;
    }
  }
}

/* Hotwire Combobox */
.search-combobox {
  .hw-combobox {
    @apply w-full inline-flex relative;
  }

  .hw-combobox__main__wrapper {
    @apply relative w-full;
  }

  .hw-combobox__input {
    @apply form-control;
  }

  .hw-combobox__label {
    @apply absolute top-2 left-3 text-xs text-gray-500 z-10;
  }

  .hw-combobox__listbox {
    @apply absolute z-50 w-full max-h-72 p-1 space-y-0.5 bg-white border border-gray-200 rounded-lg overflow-hidden overflow-y-auto dark:bg-neutral-900 dark:border-neutral-700;
  }

  .hw-combobox__option {
    @apply flex items-center justify-start w-full px-2 py-1 min-h-9 text-sm text-black rounded cursor-pointer hover:bg-slate-50;
    @apply cursor-pointer py-2 px-4 w-full text-sm text-gray-800 hover:bg-gray-100 rounded-lg focus:outline-none focus:bg-gray-100 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:text-neutral-200 dark:focus:bg-neutral-800 selected;
  }

  .hw-combobox__handle {
    @apply absolute top-1/2 end-3 -translate-y-1/2;
  }

  &:has(label:not(:empty)) {
  }

  --hw-handle-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23737373' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='lucide lucide-search'%3E%3Ccircle cx='11' cy='11' r='8'/%3E%3Cpath d='m21 21-4.3-4.3'/%3E%3C/svg%3E");
  --hw-handle-width: 16px;
  --hw-handle-height: 16px;
  --hw-handle-offset-right: 12px;
}
